var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              staticStyle: { display: "inline-block" },
              attrs: {
                searchList: _vm.$jcpzXmglSearch,
                listQuery: _vm.listQuery,
              },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item" },
        [
          _c(
            "el-row",
            { staticStyle: { height: "100%" }, attrs: { gutter: 8 } },
            [
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 24 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bg-white",
                      staticStyle: { height: "100%" },
                      attrs: { id: "table_box_height" },
                    },
                    [
                      _c("basic-table", {
                        attrs: {
                          tableHeight: _vm.tableHeight,
                          tableHeaderList: _vm.firstHeaderList,
                          tableData: _vm.tableData,
                          listQuery: _vm.listQuery,
                          tableTotal: _vm.tableTotal,
                        },
                        on: {
                          pagination: _vm.handleCurrentChange,
                          tableRowClick: _vm.tableRowClick,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.FormDialog,
          FormList: _vm.FormList,
          title: _vm.addTitle,
          FormData: _vm.addform,
        },
        on: {
          cancelDialog: _vm.cancelDialog,
          confirmDialog: _vm.confirmDialog,
        },
      }),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.FormDialogEdit,
          FormList: _vm.FormList,
          title: _vm.EditTitle,
          FormData: _vm.modifyForm,
        },
        on: {
          cancelDialog: _vm.cancelDialogEdit,
          confirmDialog: _vm.confirmDialogEdit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }